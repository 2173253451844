import Head from 'next/head'
import { useRouter } from 'next/router'
import { formatUrl, prefixLocale } from '@helpers/env'
import { objectKeys } from '@helpers/utils'
import { LOCALES, PRODUCTION_PATH, STAGING_PATH } from '@consts/index'
import Translations from '@helpers/translations'

type CustomHeadProps = {
  desc?: string
  title?: string
  image?: {
    url: string
    type?: string
    width: string | number
    height: string | number
  }
}

const basePath =
  process.env.API_ENV === 'production' ? PRODUCTION_PATH : STAGING_PATH

function getCanonicalUrl(path: string) {
  return `${basePath}${prefixLocale()}${path}`
}

function getHrefLangUrl(
  path: string,
  lang: (typeof LOCALES)[keyof typeof LOCALES] = 'en-GB'
) {
  const locale = objectKeys(LOCALES).find(key => LOCALES[key] === lang)
  const prefix = locale === 'GB' ? '' : `/${locale?.toLowerCase()}`
  return `${basePath}${prefix}${path}`
}

const CustomHead = ({ desc, title, image }: CustomHeadProps) => {
  const { asPath } = useRouter()

  const path = !/new-user|index/.test(asPath) ? formatUrl(asPath) : ''
  const pageUrl = getCanonicalUrl(path)
  const refactoredTitle = title
    ? `${title} - SPOKE`
    : `${Translations.localise('common.seo.site-title')}`

  const description =
    desc || `${Translations.localise('common.seo.site-description')}`
  return (
    <Head>
      <title key="title">{refactoredTitle}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, interactive-widget=overlays-content"
      />
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={refactoredTitle} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      {!!image?.url && <meta property="og:image" content={image.url} />}
      {!!image?.type && <meta property="og:image:type" content={image.type} />}
      {!!image?.width && (
        <meta property="og:image:width" content={`${image.width}`} />
      )}
      {!!image?.height && (
        <meta property="og:image:height" content={`${image.height}`} />
      )}
      <meta property="og:url" content={pageUrl} key="og:url" />
      <link rel="canonical" href={pageUrl} key="canonical" />
      <link
        rel="alternate"
        href={getHrefLangUrl(path, 'en-GB')}
        hrefLang="x-default"
        key="alternate"
      />

      {objectKeys(LOCALES).map(locale => (
        <link
          key={`hrefLang-${locale}`}
          rel="alternate"
          hrefLang={LOCALES[locale]}
          href={getHrefLangUrl(path, LOCALES[locale])}
        />
      ))}
    </Head>
  )
}

export default CustomHead
