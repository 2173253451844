import uniqid from 'uniqid'
import {
  STORE_ANALYTICS_CODES,
  STORES_PHONE_NUMBERS,
  STORES_SMS_NUMBERS,
  STORES_HELPDESK_LINKS,
  STORE_HELPDESK_OPEN_TIMES,
  LOCALES,
  CURRENCY_CODES
} from '@consts/index'

export function isProdEnv() {
  return process.env.API_ENV === 'production'
}

export const isBrowser = () => !!process.browser

export function getDomain() {
  return process.env.SHOP_URL
}

export function prefixLocale() {
  return process.env.SHOP_LOCALE !== 'GB'
    ? `/${process.env.SHOP_LOCALE.toLowerCase()}`
    : ''
}

export function getLink(url = '') {
  return /^data:application|^https?:\/\/|^mailto?:|^tel?:/.test(url)
    ? url
    : (
        (/production|staging/.test(process.env.API_ENV) ? getDomain() : '') +
        prefixLocale() +
        url
      ).replace(/(.{1,})\/$/, '$1')
}

export function getKey(text) {
  return uniqid(text)
}

export function getApiUrl() {
  return process.env.API_URL
}

export function storeUrl(el) {
  return `${process.env.API_URL}/${el}`
}

export function formatUrl(url = '') {
  const removeLocale = url.split('/')
  if (
    removeLocale.includes('us') ||
    removeLocale.includes('de') ||
    removeLocale.includes('gb') ||
    removeLocale.includes('eu')
  ) {
    removeLocale.splice(0, 2).join()
    return `/${removeLocale.splice(0, 2).join('/')}`
  }

  const fixDangerousPath = url.split('')
  if (
    fixDangerousPath.indexOf('/', 0) === -1 ||
    fixDangerousPath.indexOf('/', 0) > 0
  ) {
    fixDangerousPath.splice(0, 0, '/')
    return fixDangerousPath.join('')
  }
  return url
}

export function checkStoreIs(locale) {
  return process.env.SHOP_LOCALE.toUpperCase() === locale.toUpperCase()
}

export function getShop() {
  return process.env.SHOP
}

export function getAnalyticsCountryCode() {
  return STORE_ANALYTICS_CODES[process.env.SHOP_LOCALE.toLowerCase()]
}

export function getHref(type, handle) {
  const url = `/${type}/${handle}`
  return getLink(url)
}

export function replaceLocale(locale = '') {
  const path = window.location.pathname.replace(/^\/de|\/us|\/eu|\/gb$/g, '')
  return (
    (/production|staging/.test(process.env.API_ENV) ? getDomain() : '') +
    locale +
    path +
    window.location.search
  )
}

export function getLocale() {
  return LOCALES[process.env.SHOP_LOCALE]
}

export function getCurrencyCode() {
  return CURRENCY_CODES[process.env.SHOP_LOCALE]
}

export function getPhoneNumber() {
  return STORES_PHONE_NUMBERS.phone[process.env.SHOP_LOCALE.toLowerCase()]
}

export function getWhatsAppDisplayNumber() {
  return STORES_SMS_NUMBERS.whatsApp
}

export function getWhatsAppPhoneNumber() {
  return STORES_PHONE_NUMBERS.whatsApp
}

export function getHelpdeskLink() {
  return STORES_HELPDESK_LINKS[process.env.SHOP_LOCALE.toLowerCase()]
}

export function getSMSNumber() {
  return STORES_SMS_NUMBERS.phone[process.env.SHOP_LOCALE.toLowerCase()]
}

export function getHelpdeskOpenTimes() {
  return STORE_HELPDESK_OPEN_TIMES[process.env.SHOP_LOCALE.toLowerCase()]
}

export function isBot() {
  const botPattern =
    '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
  const re = new RegExp(botPattern, 'i')
  return re.test(navigator.userAgent)
}
