/* eslint-disable import/prefer-default-export */
import { SIZES, COOKIE_EXPIRE_3_MONTHS } from '@consts/index'
import { setCookie, getCookieByName } from '@helpers/session'
import { isEmptyObject, objectKeys } from '@helpers/utils'
import trackError from '@helpers/trackError'
import { upperFirstLetter } from '@helpers/utils'

export function parseQuery(queryString: string): Record<string, string> {
  const query: Record<string, string> = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = decodeURIComponent(
      pairs[i].replace(/%([^\d{2}][\u2013|\u2014])/g, '$1')
    ).split('=')
    query[pair[0]] = pair[1] || ''
  }
  return query
}

function getFitFromUrl(): Partial<Record<string, string>> {
  const fitFinder = {
    sl: 'shortsleg',
    b: 'build',
    l: 'leg',
    w: 'waist',
    s: 'size',
    c: 'cut',
    lh: 'length',
    p: 'pack'
  } as const
  const urlParams = parseQuery(window.location.search) as typeof fitFinder
  return objectKeys(urlParams).reduce((fit, param) => {
    const newUrlParams: Record<string, string> = {}
    newUrlParams[param] = upperFirstLetter(urlParams[param])

    return fitFinder[param] &&
      SIZES[fitFinder[param]].findIndex(f => {
        newUrlParams[param] = upperFirstLetter(urlParams[param])
        return (
          f.toLowerCase().replace('build ', '') ===
          urlParams[param].toLowerCase()
        )
      }) > -1
      ? {
          ...fit,
          [fitFinder[param].toLowerCase()]: urlParams[param]
        }
      : fit
  }, {})
}

function createFitObject(
  fitFromUrl: Partial<Record<string, string>>,
  parsedCookie: Partial<Record<string, string>> = {}
): Partial<Record<string, string>> {
  if (!isEmptyObject(fitFromUrl)) {
    return fitFromUrl
  }

  return parsedCookie
}

export function initFit(): Partial<Record<string, string>> {
  try {
    const parsedCookie = JSON.parse(
      getCookieByName('filters') || '{}'
    ) as Partial<Record<string, string>>
    const fitFromUrl = getFitFromUrl()
    const fit = createFitObject(fitFromUrl, parsedCookie)
    const newFilters = objectKeys(fit || {}).reduce((json, item) => {
      const type = item.replace('swimshortsleg', 'shortsleg')
      const size = SIZES[type.toLowerCase() as keyof typeof SIZES]
      const arrayToCrawl =
        type.toLowerCase() === 'shortsleg'
          ? [...new Set([...SIZES.shortsleg, ...size])]
          : size

      const isValidFitSizeName =
        !!size &&
        arrayToCrawl.findIndex((value: string) => {
          return (
            value.toLowerCase().replace('build ', '') ===
            ((fit || {})[type] || '').toString().toLowerCase()
          )
        }) > -1
      return !isValidFitSizeName
        ? json
        : { ...json, [type.toLowerCase()]: (fit || {})[type] }
    }, {})

    if (!isEmptyObject(fitFromUrl)) {
      setCookie(
        'filters',
        JSON.stringify(newFilters).replace('shortsleg', 'shortsLeg'),
        COOKIE_EXPIRE_3_MONTHS
      )
    }

    return newFilters
  } catch (error) {
    const filterCookie = getCookieByName('filters')
    setCookie('filters', '{}', COOKIE_EXPIRE_3_MONTHS)
    trackError(new Error('error on filter cookie/sessionStorage parsing'), {
      error: error as Error,
      filterCookie
    })
    return {}
  }
}
