import React from 'react'
import styles from './styles.module.scss'

export type FlashBannerMessageProps = {
  messageDesktop: string
  messageMobile: string
  contentColor: string | null
}

function FlashBannerMessage({
  messageDesktop,
  messageMobile,
  contentColor
}: FlashBannerMessageProps) {
  return (
    <div
      className={styles.flashMsg}
      style={{ color: contentColor || undefined }}
    >
      <p className={styles.flashMsg__desktop}>{messageDesktop}</p>
      <p className={styles.flashMsg__mobile}>{messageMobile}</p>
    </div>
  )
}

export default FlashBannerMessage
