import { AnchorHTMLAttributes } from 'react'
import Translations from '@helpers/translations'
import { getLink } from '@helpers/env'
import { analytics } from '@helpers/analytics'
import { ReactComponent as HeaderLogoIcon } from '@svgs/spoke-logo.svg'
import styles from './styles.module.scss'

type HeaderLogoProps = {
  width?: string
  height?: string
  classes?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

function HeaderLogo({ width, height, classes, ...props }: HeaderLogoProps) {
  return (
    <a
      onClick={() => {
        analytics('Click', {
          category: 'Header',
          label: 'Logo'
        })
      }}
      className={`${styles.headerLogo}${classes ? ` ${classes}` : ''}`}
      href={getLink('/')}
      aria-label={Translations.localise('common.seo.site-title')}
      {...props}
    >
      <HeaderLogoIcon className="HeaderLogo" width={width} height={height} />
    </a>
  )
}

export default HeaderLogo
